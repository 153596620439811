@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');

@import url('/css/revaicons.css');

body {
  margin: 0;
  padding: 0 !important;
  overflow-y: scroll !important;
  font-family: 'Ubuntu', sans-serif;
}

a {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

/* Async loading progress loader*/
#nprogress .bar {
  background: #00b952 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #00b952 0 0 5px #00b952 !important;
}

#nprogress .spinner-icon {
  border-top-color: #00b952 !important;
  border-left-color: #00b952 !important;
}

/* Finish async loading progress loader */

.cta {
  background: linear-gradient(169.93deg, #ff8000 0%, #ffaf00 100%) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  text-transform: none !important;
  font-weight: 550 !important;
  font-size: 18px !important;
  color: #ffffff !important;
}
